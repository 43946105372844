export const translations: any = {
    // Headline
    HEADLINE_1: { en: "Hi, my name is", pl: "Cześć, nazywam się" },
    HEADLINE_2: { en: "and i'm a full-stack developer.", pl: "Programista full-stack." },
    HEADLINE_SOCIALS_CONTACT: { en: "Contact me", pl: "Skontaktuj się" },
    HEADLINE_SOCIALS_OR: { en: "or", pl: "lub" },
    HEADLINE_SOCIALS_LINKEDIN: { en: "Visit my LinkedIn profile", pl: "Odwiedź mój profil na LinkedIn" },
    HEADLINE_SOCIALS_GITHUB: { en: "Visit my GitHub profile", pl: "Odwiedź mój profil na GitHub" },
    HEADLINE_SOCIALS_GOOGLE_PLAY: { en: "Visit my Google Play profile", pl: "Odwiedź mój profil na Google Play" },

    // About me
    SECTION_ABOUT_ME: { en: "About me", pl: "O mnie" },
    SECTION_ABOUT_ME_LINE_1: { en: "I'm a self-taught programmer located near Warsaw, Poland.", pl: "Jestem programistą samoukiem mieszkającym pod Warszawą." },
    SECTION_ABOUT_ME_LINE_2: { en: "C# is my go-to language.", pl: "C# jestem moim językiem pierwszego wyboru." },
    SECTION_ABOUT_ME_LINE_3: { en: "In my free time I enjoy skiing, sailing and playing tennis.", pl: "W wolnym czasie lubię jeździć na nartach, żeglować i grać w tenisa." },

    // Skills
    SECTION_SKILLS: { en: "Skills", pl: "Umiejętności" },

    // Portfolio
    SECTION_PORTFOLIO: { en: "Portfolio", pl: "Moje projekty" },

    // Certificates
    SECTION_CERTIFICATES: { en: "Certificates", pl: "Certyfikaty" },
    SECTION_CERTIFICATES_CREDLY: { en: "Credly profile", pl: "Profil Credly" },

    // Contact
    SECTION_CONTACT: { en: "Contact", pl: "Kontakt" },
    SECTION_CONTACT_LINE_1: { en: "If you'd like get in touch, just email me at", pl: "Skontaktuj się ze mną pod adresem" },
    SECTION_CONTACT_SEND: { en: "Send email", pl: "Wyślij email" },
    SECTION_CONTACT_COPY: { en: "Copy to clipboard", pl: "Skopiuj do schowka" },

    // Footer
    FOOTER_LEFT: { en: "Made using ⚛️React", pl: "Stworzono przy użyciu ⚛️React" },
};