import { useContext } from "react";
import { AppContext } from "../App";

function PortfolioSection() {
    var appContext = useContext(AppContext);

    const projects = {
        commercial: [
            {
                startYear: 2023,
                endYear: 2024,
                name: "eBilet",
                url: "https://www.ebilet.pl/",
                jobRole: ".NET developer",
                company: "Britenet"
            },
            {
                startYear: 2022,
                endYear: 2023,
                name: "PANEK dla firm",
                url: "https://panekcs.pl/panek-dla-firm",
                jobRole: ".NET developer",
                company: "Britenet"
            }
        ],
        hobby: [
            {
                startYear: 2023,
                endYear: 2023,
                name: "SimpleRaytracer",
                url: "https://github.com/wberdowski/SimpleRaytracer",
                technologies: ".NET, GPGPU",
                jobRole: ".NET developer"
            },
            {
                startYear: 2022,
                endYear: 2022,
                name: "EasySplit",
                url: "https://easysplit.wberdowski.pl/",
                technologies: ".NET, PostgreSQL, React, Bootstrap",
                jobRole: "Full-stack developer"
            },
            {
                startYear: 2022,
                endYear: 2022,
                name: "PokerParty",
                url: "https://github.com/wberdowski/PokerPartyGL",
                technologies: ".NET, OpenTK",
                jobRole: ".NET developer"
            },
            {
                startYear: 2021,
                endYear: 2021,
                name: "AnchorAlarm",
                url: "https://play.google.com/store/apps/details?id=pl.wberdowski.anchoralarm",
                technologies: ".NET, Xamarin",
                jobRole: "Mobile developer"
            },
            {
                startYear: 2021,
                endYear: 2021,
                name: "ArduinoTemp",
                url: "https://arduinotemp.wberdowski.pl/",
                technologies: ".NET, PostgreSQL, React, Bootstrap",
                jobRole: "Full-stack developer"
            },
            {
                startYear: 2021,
                endYear: 2021,
                name: "LogicSolver",
                url: "https://wberdowski.github.io/LogicSolver/",
                technologies: ".NET, JavaScript",
                jobRole: "Full-stack developer"
            },
            {
                startYear: 2020,
                endYear: 2023,
                name: "BitSerializer",
                url: "https://github.com/wberdowski/BitSerializer",
                technologies: ".NET",
                jobRole: ".NET developer"
            }
        ]
    };

    return (
        <section id="portfolio">
            <div className="container d-flex gap-4 flex-column py-5">
                <h1 className="mb-4">Projekty i doświadczenie</h1>

                <ul className="nav nav-pills gap-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-commercial" type="button" role="tab">Komercyjne</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-hobby" type="button" role="tab">Hobbistyczne</button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-commercial" role="tabpanel">
                        <div className="list-group list-group-flush rounded">
                            {projects.commercial.map(p =>
                                <a href={p.url} target="_blank" className="list-group-item list-group-item-action">
                                    <div className="d-flex flex-column py-2 gap-1">
                                        <div className="text-primary fs-4">{p.startYear == p.endYear ? `${p.startYear}` : `${p.startYear} - ${p.endYear}`}</div>
                                        <div className="fs-5">{p.name}</div>
                                        <div className="text-muted">{p.jobRole} {p.company != null ? `(${p.company})` : ""}</div>
                                    </div>
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-hobby" role="tabpanel">
                        <div className="list-group list-group-flush rounded">
                            {projects.hobby.map(p =>
                                <a href={p.url} target="_blank" className="list-group-item list-group-item-action">
                                    <div className="d-flex flex-column py-2 gap-1">
                                        <div className="text-primary fs-4">{p.startYear == p.endYear ? `${p.startYear}` : `${p.startYear} - ${p.endYear}`}</div>
                                        <div className="fs-5">{p.name}</div>
                                        <div className="fw-bold"><small>{p.technologies}</small></div>
                                        <div className="text-muted">{p.jobRole}</div>
                                    </div>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PortfolioSection;