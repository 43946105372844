import React, { useEffect, useState } from 'react';
import './App.css';
import ContactSection from './components/ContactSection';
import FooterSection from './components/FooterSection';
import HeadlineSection from './components/HeadlineSection';
import PortfolioSection from './components/PortfolioSection';
import SkillsSection from './components/SkillsSection';
import { IAppContext } from './interfaces/IAppContext';
import './themes/theme-dark.scss';

export const AppContext = React.createContext<IAppContext>({
    language: "en"
});

function App() {
    const [preferredLanguage, setPreferredLanguage] = useState<string>("en");

    useEffect(() => {
        if (navigator.language.startsWith("pl")) {
            setPreferredLanguage("pl");
        }
    }, []);

    return (
        <AppContext.Provider value={{ language: preferredLanguage }} >
            <div className="App" data-bs-target="#navbar">
                <HeadlineSection />
                {/*<AboutMeSection />*/}
                <SkillsSection />
                {/*<CertificatesSection />*/}
                <PortfolioSection />
                <ContactSection />
                <FooterSection />
            </div>
        </AppContext.Provider>
    );
}

export default App;
