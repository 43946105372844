import { useContext } from 'react';
import { AppContext } from '../App';
import { translations } from '../translations';

function FooterSection() {
    var appContext = useContext(AppContext);

    return (
        <>
            <footer className="border-top desktop-visible">
                <div className="container d-flex gap-3">
                    <div className="text-muted flex-fill">{translations["FOOTER_LEFT"][appContext.language]}</div>
                    <div className="">&copy; {new Date().getFullYear()} Wojciech Berdowski</div>
                </div>
            </footer><footer className="border-top mt-5 mobile-visible">
                <div className="container d-flex gap-3 flex-column align-items-center">
                    <div className="text-muted flex-fill">{translations["FOOTER_LEFT"][appContext.language]}</div>
                    <div className="">&copy; {new Date().getFullYear()} Wojciech Berdowski</div>
                </div>
            </footer>
        </>
    );
}

export default FooterSection;