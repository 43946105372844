import { useContext } from 'react';
import { AppContext } from '../App';
import { translations } from '../translations';

function SkillsSection() {
    var appContext = useContext(AppContext);

    return (
        <section id="skills">
            <div className="container d-flex gap-4 flex-column py-5">
                <h1 className="mb-4">{translations["SECTION_SKILLS"][appContext.language]}</h1>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 gy-4">
                    <div className="col">
                        <div className="card h-100 py-2">
                            <div className="card-body d-flex flex-column align-items-center gap-2">
                                <i className="bi bi-code display-1 text-primary"></i>
                                <span className="fs-5 text-center">Tworzenie oprogramowania</span>
                                <span className="text-center text-muted">Aplikacje desktopowe, web i mobilne</span>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card h-100 py-2">
                            <div className="card-body d-flex flex-column align-items-center gap-2">
                                <i className="bi bi-database display-1 text-primary"></i>
                                <span className="fs-5 text-center">Bazy danych</span>
                                <span className="text-center text-muted">Projektowanie, wdrażanie i zarządzanie</span>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card h-100 py-2">
                            <div className="card-body d-flex flex-column align-items-center gap-2">
                                <i className="bi bi-cloud display-1 text-primary"></i>
                                <span className="fs-5 text-center">Technologie chmurowe</span>
                                <span className="text-center text-muted">Konfiguracja i wdrażanie serwisów w Azure</span>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card h-100 py-2">
                            <div className="card-body d-flex flex-column align-items-center gap-2">
                                <i className="bi bi-arrow-repeat display-1 text-primary"></i>
                                <span className="fs-5 text-center">CI/CD</span>
                                <span className="text-center text-muted">Konfiguracja GitHub Actions i Jenkins</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SkillsSection;