import { useContext } from "react";
import { AppContext } from "../App";
import { translations } from "../translations";

function HeadlineSection() {
    var appContext = useContext(AppContext);

    return (
        <section id="headline" className="d-flex flex-column">
            {/*<canvas id="headline-canvas" className="position-absolute top-0 start-0" width="1902" height="1080"></canvas>*/}

            <div className="container-sm d-flex gap-0 flex-column">
                <div className="d-flex align-items-center desktop-visible gap-5">
                    <div className="d-flex flex-column flex-fill gap-3 text-nowrap">
                        {/*<div className="display-6">{translations["HEADLINE_1"][appContext.language]}</div>*/}
                        <div className="display-3"><b>Wojciech Berdowski</b></div>
                        <div className="display-6">{translations["HEADLINE_2"][appContext.language]}</div>

                        <div className="d-flex social-links gap-3 mt-4 align-items-center">
                            <a href="#contact" className="btn btn-outline-primary btn-lg">{translations["HEADLINE_SOCIALS_CONTACT"][appContext.language]}</a>
                            <span className="fs-4">{translations["HEADLINE_SOCIALS_OR"][appContext.language]}</span>
                            <div className="d-flex gap-3 justify-content-center">
                                <a href="https://www.linkedin.com/in/wojciech-berdowski" rel="noreferer" target="_blank" title={translations["HEADLINE_SOCIALS_LINKEDIN"][appContext.language]}><img src="res/LinkedIn_icon.svg" /></a>
                                <a href="https://github.com/wberdowski" rel="noreferer" target="_blank" title={translations["HEADLINE_SOCIALS_GITHUB"][appContext.language]} className="invert-dark"><img src="res/github-mark.svg" /></a>
                                <a href="https://play.google.com/store/apps/dev?id=6362840393157679675" rel="noreferer" target="_blank" title={translations["HEADLINE_SOCIALS_GOOGLE_PLAY"][appContext.language]}><img src="res/Google_Play_Arrow_logo.svg" /></a>
                            </div>
                        </div>
                    </div>

                    <div className="my-picture shadow" style={{ backgroundImage: "url(res/me.jpg)" }} ></div>
                </div>

                <div className="d-flex align-items-center flex-column mobile-visible gap-4">
                    <div className="my-picture shadow" style={{ backgroundImage: "url(res/me.jpg)" }} ></div>

                    <div className="d-flex flex-column flex-fill text-center gap-2">
                        {/*<div className="fs-4">{translations["HEADLINE_1"][appContext.language]}</div>*/}
                        <div className="display-4"><b>Wojciech Berdowski</b></div>
                        <div className="fs-4">{translations["HEADLINE_2"][appContext.language]}</div>

                        <div className="d-flex flex-column social-links gap-3 justify-content-center mt-4">
                            <a href="#contact" className="btn btn-outline-primary btn-lg">{translations["HEADLINE_SOCIALS_CONTACT"][appContext.language]}</a>
                            <span className="fs-4">{translations["HEADLINE_SOCIALS_OR"][appContext.language]}</span>
                            <div className="d-flex gap-3 justify-content-center">
                                <a href="https://www.linkedin.com/in/wojciech-berdowski" rel="noreferer" target="_blank" title={translations["HEADLINE_SOCIALS_LINKEDIN"][appContext.language]}><img src="res/LinkedIn_icon.svg" /></a>
                                <a href="https://github.com/wberdowski" rel="noreferer" target="_blank" title={translations["HEADLINE_SOCIALS_GITHUB"][appContext.language]} className="invert-dark"><img src="res/github-mark.svg" /></a>
                                <a href="https://play.google.com/store/apps/dev?id=6362840393157679675" rel="noreferer" target="_blank" title={translations["HEADLINE_SOCIALS_GOOGLE_PLAY"][appContext.language]}><img src="res/Google_Play_Arrow_logo.svg" /></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default HeadlineSection;