import { useContext } from 'react';
import { AppContext } from '../App';
import { translations } from '../translations';

function ContactSection() {
    var appContext = useContext(AppContext);

    return (
        <section id="contact" className="py-5">
            <div className="container d-flex gap-4 flex-column">
                <h1 className="mb-4">{translations["SECTION_CONTACT"][appContext.language]}</h1>

                <h5 className="text-primary">E-mail</h5>

                <a href="mailto:wberdowski@outlook.com" title={translations["SECTION_CONTACT_SEND"][appContext.language]}>
                    <i className="bi bi-envelope-fill me-2"></i> wberdowski@outlook.com
                </a>

                <h5 className="text-primary">Linki</h5>

                <a href="https://linkedin.com/in/wojciech-berdowski" target="_blank">
                    <i className="bi bi-linkedin me-2"></i> LinkedIn
                </a>

                <a href="https://github.com/wberdowski" target="_blank">
                    <i className="bi bi-github me-2"></i> GitHub
                </a>

                <a href="https://www.credly.com/users/wojtek-berdowski" target="_blank">
                    <i className="bi bi-patch-check-fill me-2"></i> Credly
                </a>

                <a href="https://play.google.com/store/apps/dev?id=6362840393157679675" target="_blank">
                    <i className="bi bi-google-play me-2"></i> Google Play
                </a>
            </div>

            <div className="toast align-items-center text-bg-primary border-0 position-fixed bottom-0 start-50 translate-middle m-0" id="copySuccessToast">
                <div className="d-flex">
                    <div className="toast-body">
                        Adres email skopiowany do schowka.
                    </div>
                    <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"></button>
                </div>
            </div>
        </section>
    );
}

export default ContactSection;